import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  Container, Grid, Card, CardMedia, CardContent, Typography, 
  Button, Pagination, AppBar, Toolbar, CircularProgress,
  Select, MenuItem, FormControl, InputLabel, TextField,
  Box, Slider
} from '@mui/material';

const ProductGallery = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [productsPerPage, setProductsPerPage] = useState(12);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortOrder, setSortOrder] = useState('');
  const [filters, setFilters] = useState({
    gender:'',
    brand: '',
    color: '',
    size: '',
    stockValueRange: [0, 100],
    priceRange: [0, 1000],
    promoRange: [0, 100],
    parentCategory: '',
    productCategory: ''
  });

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    applyFiltersAndSort();
  }, [products, filters, sortOrder]);

  const fetchProducts = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get('https://product-gallery-backend.vercel.app/api/products');
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
      setError('Failed to fetch products. Please try again later.');
    }
    setLoading(false);
  };

  const applyFiltersAndSort = () => {
    let result = [...products];

    // Apply filters
    result = result.filter(product => {
      return (
	(filters.gender === '' || product.gender === filters.gender) &&
        (filters.brand === '' || product.productBrand === filters.brand) &&
        (filters.color === '' || product.productColor === filters.color) &&
        (filters.size === '' || (product.sizesAvailable && product.sizesAvailable.includes(filters.size))) &&
        (product.stockValue >= filters.stockValueRange[0] && product.stockValue <= filters.stockValueRange[1]) &&
        (product.productPriceNew >= filters.priceRange[0] && product.productPriceNew <= filters.priceRange[1]) &&
        (product.promo >= filters.promoRange[0] && product.promo <= filters.promoRange[1]) &&
        (filters.parentCategory === '' || product.parentCategory === filters.parentCategory) &&
        (filters.productCategory === '' || product.productCategory === filters.productCategory)
      );
    });

    // Apply sorting
    if (sortOrder === 'asc') {
      result.sort((a, b) => a.productPriceNew - b.productPriceNew);
    } else if (sortOrder === 'desc') {
      result.sort((a, b) => b.productPriceNew - a.productPriceNew);
    }

    setFilteredProducts(result);
    setTotalPages(Math.ceil(result.length / productsPerPage));
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleRangeChange = (name) => (event, newValue) => {
    setFilters(prev => ({ ...prev, [name]: newValue }));
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleProductsPerPageChange = (event) => {
    setProductsPerPage(event.target.value);
    setTotalPages(Math.ceil(filteredProducts.length / event.target.value));
    setPage(1);
  };

  const getUniqueValues = (key) => {
    return [...new Set(products.map(product => product[key]))];
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            My Outfit
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Box sx={{ mb: 4 }}>
          <Grid container spacing={2}>
	     <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <InputLabel>Gender</InputLabel>
                <Select name="gender" value={filters.gender} onChange={handleFilterChange} label="Gender">
                  <MenuItem value="">All</MenuItem>
                  {getUniqueValues('gender').map(gender => (
                    <MenuItem key={gender} value={gender}>{gender}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <InputLabel>Sort by Price</InputLabel>
                <Select value={sortOrder} onChange={handleSortChange} label="Sort by Price">
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="asc">Low to High</MenuItem>
                  <MenuItem value="desc">High to Low</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <InputLabel>Brand</InputLabel>
                <Select name="brand" value={filters.brand} onChange={handleFilterChange} label="Brand">
                  <MenuItem value="">All</MenuItem>
                  {getUniqueValues('productBrand').map(brand => (
                    <MenuItem key={brand} value={brand}>{brand}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <InputLabel>Color</InputLabel>
                <Select name="color" value={filters.color} onChange={handleFilterChange} label="Color">
                  <MenuItem value="">All</MenuItem>
                  {getUniqueValues('productColor').map(color => (
                    <MenuItem key={color} value={color}>{color}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <InputLabel>Size</InputLabel>
                <Select name="size" value={filters.size} onChange={handleFilterChange} label="Size">
                  <MenuItem value="">All</MenuItem>
                  {getUniqueValues('sizesAvailable').flat().filter(Boolean).map(size => (
                    <MenuItem key={size} value={size}>{size}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography gutterBottom>Stock Value Range</Typography>
              <Slider
                value={filters.stockValueRange}
                onChange={handleRangeChange('stockValueRange')}
                valueLabelDisplay="auto"
                min={0}
                max={100}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography gutterBottom>Price Range</Typography>
              <Slider
                value={filters.priceRange}
                onChange={handleRangeChange('priceRange')}
                valueLabelDisplay="auto"
                min={0}
                max={1000}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography gutterBottom>Promo Range</Typography>
              <Slider
                value={filters.promoRange}
                onChange={handleRangeChange('promoRange')}
                valueLabelDisplay="auto"
                min={0}
                max={100}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Parent Category</InputLabel>
                <Select name="parentCategory" value={filters.parentCategory} onChange={handleFilterChange} label="Parent Category">
                  <MenuItem value="">All</MenuItem>
                  {getUniqueValues('parentCategory').map(category => (
                    <MenuItem key={category} value={category}>{category}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Product Category</InputLabel>
                <Select name="productCategory" value={filters.productCategory} onChange={handleFilterChange} label="Product Category">
                  <MenuItem value="">All</MenuItem>
                  {getUniqueValues('productCategory').map(category => (
                    <MenuItem key={category} value={category}>{category}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        {loading ? (
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        ) : error ? (
          <Typography variant="h5" align="center" color="error">{error}</Typography>
        ) : filteredProducts.length === 0 ? (
          <Typography variant="h5" align="center">No products found.</Typography>
        ) : (
          <>
            <Grid container spacing={3}>
              {filteredProducts.slice((page - 1) * productsPerPage, page * productsPerPage).map((product) => (
                <Grid item key={product.productReference} xs={12} sm={6} md={4}>
                  <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <CardMedia
                      component="img"
                      sx={{
                        height: 200,
                        objectFit: 'contain',
                        bgcolor: 'grey.100'
                      }}
                      image={product.productImage}
                      alt={product.productName}
                    />
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography gutterBottom variant="h6" component="h2" noWrap>
                        {product.productName}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {product.productBrand}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Color: {product.productColor}
                      </Typography>
                      <Typography variant="h6" color="primary" sx={{ mt: 1 }}>
                        {product.productPriceNew} QAR
                        {product.productPriceOld && (
                          <Typography variant="body2" color="text.secondary" component="span" sx={{ textDecoration: 'line-through', ml: 1 }}>
                            {product.productPriceOld} QAR
                          </Typography>
                        )}
                      </Typography>
                      {product.promo > 0 && (
                        <Typography variant="body2" color="error">
                          {product.promo}% OFF
                        </Typography>
                      )}
                      <Typography variant="body2" color="text.secondary">
                        Stock: {product.stockValue}
                      </Typography>
		      <Typography variant="body2" color="text.secondary">
                        Size: {product.sizesAvailable}
                      </Typography>
                    </CardContent>
                    <Button size="small" color="primary" href={product.productLink} target="_blank" rel="noopener noreferrer">
                      View Details
                    </Button>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <FormControl sx={{ minWidth: 120 }}>
                <InputLabel>Products per page</InputLabel>
                <Select
                  value={productsPerPage}
                  onChange={handleProductsPerPageChange}
                  label="Products per page"
                >
                  <MenuItem value={12}>12</MenuItem>
                  <MenuItem value={24}>24</MenuItem>
                  <MenuItem value={36}>36</MenuItem>
                  <MenuItem value={48}>48</MenuItem>
                </Select>
              </FormControl>
              <Pagination 
                count={totalPages} 
                page={page} 
                onChange={handlePageChange} 
                color="primary" 
              />
            </Box>
          </>
        )}
      </Container>
    </>
  );
};

export default ProductGallery;
